import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { api } from "@/lib/api-client";
import { cn } from "@/utils";
import { getPaymentTypeLabel, PaymentType } from "@/utils/pos-printer";
import {
  Divider,
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  Skeleton,
} from "@nextui-org/react";

export const ClosingDetailsModal = ({
  businessPremiseId,
  closureId,
  handleClose,
}: {
  businessPremiseId: string;
  closureId?: number;
  handleClose: () => void;
}) => {
  const {
    data: closureData,
    isFetching: isFetchingClosureData,
    processedErrorMessage: getClosureErrorMessage,
  } = api.taxRegister.useGetCashRegisterClosure({
    businessPremiseId,
    closureId,
  });

  const hasData = closureData?.documentsByUserAndPaymentMethodFormatted
    ? Object.values(closureData.documentsByUserAndPaymentMethodFormatted)
        .length > 0
      ? true
      : false
    : false;

  const documentsByUserAndPaymentMethod =
    closureData?.documentsByUserAndPaymentMethodFormatted
      ? Object.values(
          closureData?.documentsByUserAndPaymentMethodFormatted,
        ).map((doc) => ({
          ...doc,
          isSkeleton: false,
        }))
      : [
          {
            name: "Loading",
            lastName: "User",
            payments: {
              card: {
                formatted: "",
              },
              cash: {
                formatted: "",
              },
              coupon: {
                formatted: "",
              },
              total: {
                formatted: "",
              },
            },
            products: {},
            productsTotal: {
              formatted: "",
            },
            services: {},
            servicesTotal: {
              formatted: "",
            },
            giftCards: {},
            giftCardsTotal: {
              formatted: "",
            },
            isSkeleton: true,
          },
        ];

  const totalsByPaymentMethod = closureData?.totalsByPaymentMethodFormatted
    ? Object.entries(closureData?.totalsByPaymentMethodFormatted)
    : [];

  const date =
    closureData?.dateFrom === closureData?.dateTo
      ? closureData?.dateFrom
      : `${closureData?.dateFrom} - ${closureData?.dateTo}`;

  return (
    <Modal
      isOpen={closureId != null}
      onClose={handleClose}
      size="xl"
      scrollBehavior="outside"
    >
      <ModalContent>
        <ModalHeader>
          <Trans>Podrobnosti zaključka</Trans>
        </ModalHeader>
        <ModalBody className="mb-4">
          <LimeAlert color="danger" message={getClosureErrorMessage} />

          <Skeleton isLoaded={!isFetchingClosureData} className="rounded-lg">
            <p className="text-sm">
              <Trans>Datum:</Trans> {date}
            </p>
          </Skeleton>

          {!hasData ? (
            <p className="text-sm text-gray-500">
              <Trans>Ni podatkov</Trans>
            </p>
          ) : undefined}

          <div className="flex flex-col gap-2">
            {documentsByUserAndPaymentMethod.map((doc) => (
              <div className="mb-4 rounded-lg bg-divider px-4 pb-4 pt-3">
                <Skeleton isLoaded={!doc.isSkeleton}>
                  <p className="mb-2 font-semibold">
                    {doc.name} {doc.lastName}
                  </p>
                </Skeleton>
                <div className="flex flex-col gap-1">
                  <p className="mt-2 text-sm font-semibold">
                    <Trans>Storitve</Trans>
                  </p>
                  {Object.values(doc.services).map((value) => (
                    <PaymentRow
                      label={value.name}
                      value={`${value.amount}x ${value.piecePrice} ; ${value.totalPrice}`}
                      isSkeleton={doc.isSkeleton}
                    />
                  ))}
                  <PaymentRow
                    label={t`Skupaj storitve`}
                    value={doc.servicesTotal.formatted}
                    isSkeleton={doc.isSkeleton}
                    isTotal
                  />

                  <Divider className="mt-2 bg-gray-200" />

                  <p className="mt-2 text-sm font-semibold">
                    <Trans>Produkti</Trans>
                  </p>
                  {Object.values(doc.products).map((value) => (
                    <PaymentRow
                      label={value.name}
                      value={`${value.amount}x ${value.piecePrice} ; ${value.totalPrice}`}
                      isSkeleton={doc.isSkeleton}
                    />
                  ))}
                  <PaymentRow
                    label={t`Skupaj produkti`}
                    value={doc.productsTotal.formatted}
                    isSkeleton={doc.isSkeleton}
                    isTotal
                  />

                  <Divider className="mt-2 bg-gray-200" />

                  <p className="mt-2 text-sm font-semibold">
                    <Trans>Izdani darilni boni</Trans>
                  </p>
                  {Object.values(doc.giftCards).map((value) => (
                    <PaymentRow
                      label={value.code}
                      value={`${value.initialAmount}`}
                      isSkeleton={doc.isSkeleton}
                      cardAmount={value.cardAmount}
                      cashAmount={value.cashAmount}
                    />
                  ))}
                  <PaymentRow
                    label={t`Skupaj izdani darilni boni`}
                    value={doc.giftCardsTotal.formatted}
                    isSkeleton={doc.isSkeleton}
                    isTotal
                  />

                  <Divider className="my-2 bg-gray-300" />

                  {Object.entries(doc.payments)
                    .filter(([paymentMethod, _]) => paymentMethod !== "total")
                    .map(([paymentMethod, value]) => (
                      <PaymentRow
                        label={getPaymentTypeLabel(
                          paymentMethod as PaymentType,
                        )}
                        value={value.formatted}
                        isSkeleton={doc.isSkeleton}
                      />
                    ))}

                  <PaymentRow
                    label={t`Skupaj`}
                    value={doc.payments["total"].formatted}
                    isTotal
                    isSkeleton={doc.isSkeleton}
                  />
                </div>
              </div>
            ))}

            <Divider className="mb-3" />

            <div className="px-2">
              {totalsByPaymentMethod
                .filter(([paymentMethod, _]) => paymentMethod !== "total")
                .map(([paymentMethod, value]) => (
                  <PaymentRow
                    label={getPaymentTypeLabel(paymentMethod as PaymentType)}
                    value={value.formatted}
                  />
                ))}

              <PaymentRow
                label={t`Skupaj`}
                value={
                  closureData?.totalsByPaymentMethodFormatted["total"]
                    ?.formatted || "/"
                }
                isTotal
              />
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const PaymentRow = ({
  label,
  value,
  cashAmount,
  cardAmount,
  isTotal,
  isSkeleton,
}: {
  label: string;
  value: string;
  cashAmount?: string;
  cardAmount?: string;
  isTotal?: boolean;
  isSkeleton?: boolean;
}) => {
  return (
    <Skeleton isLoaded={!isSkeleton}>
      <div>
        <div
          className={cn("flex justify-between gap-2 text-sm", {
            "mt-2": isTotal,
          })}
        >
          <p
            className={cn({
              "font-semibold": isTotal,
              "w-1/2": !isTotal,
            })}
          >
            {label}
          </p>
          <p>{value}</p>
        </div>
        {cashAmount || cardAmount ? (
          <div className="mb-2 ml-4 text-right">
            {cashAmount ? (
              <p className="text-xs">Gotovina: {cashAmount}</p>
            ) : undefined}
            {cardAmount ? (
              <p className="text-xs">Kartica: {cardAmount}</p>
            ) : undefined}
          </div>
        ) : undefined}
      </div>
    </Skeleton>
  );
};
